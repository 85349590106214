<template>
    <div>
        <b-modal id="popup-alterar-senha"
                 ref="popup-alterar-senha"
                 :title="usuarioNovo ? 'Senha de acesso' : 'Alterar senha'"
                 centered size="sm"
                 hide-footer
                 no-close-on-esc
                 :hide-header-close="usuarioNovo"
                 no-close-on-backdrop>

            <form>
                <b-form-group label="Senha atual *" label-for="input-senha-atual">
                    <b-form-input type="password" id="input-senha-atual" v-model="senha.atual" required></b-form-input>
                    <p v-if="validarSenhaAtual" class="erro-input">Senha atual incorreta</p>
                    <p v-if="msgFalhaSenhaAtual" class="erro-input">{{msgFalhaSenhaAtual}}</p>
                </b-form-group>

                <b-form-group label="Nova senha *" label-for="input-senha-atual">
                    <b-form-input type="password" id="input-senha-nova" v-model="senha.nova" required></b-form-input>
                    <p v-if="validarSenhaNova" class="erro-input">Senha deve ter no mínimo 8 caracteres, contendo letras e números.</p>
                </b-form-group>

                <b-form-group label="Confirmar senha *" label-for="input-senha-confirma">
                    <b-form-input type="password" id="input-senha-confirma" v-model="senha.confirma" required></b-form-input>
                    <p v-if="validarSenhaDiferentes" class="erro-input">As senhas estão diferentes</p>
                </b-form-group>

            </form>

            <div class="w-100 py-3 text-right">
                <button v-if="usuarioNovo == false" class="btn-sm btn-secondary mr-2" @click="fecharPopup">CANCELAR</button>
                <button class="btn-sm btn-primary" @click="validarSenhas">SALVAR</button>
            </div>
        </b-modal>        
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    
    export default {
        data() {
            return {
                senha: {
                    atual: '',
                    nova: '',
                    confirma: ''
                },
                msgFalhaSenhaAtual: '',
                validarSenhaAtual: false,
                validarSenhaNova: false,
                validarSenhaDiferentes: false
            }
        },
        props: {
            usuarioNovo: {
                type: Boolean
            } 
        },
                
        methods: {
            ...mapActions('authentication', ['alterarSenha']),
            mostrarPopup() {
                this.resetInputs();
                this.$refs['popup-alterar-senha'].show();
            },
            fecharPopup() {
                this.resetInputs();
                this.$refs['popup-alterar-senha'].hide();
            },
            resetInputs() {
                this.senha.atual = '';
                this.senha.nova = '',
                this.senha.confirma = '',

                this.msgFalhaSenhaAtual = '';
                this.validarSenhaAtual = false,
                this.validarSenhaNova = false,
                this.validarSenhaDiferentes = false
            },
            salvarSenha() {
                if (this.validarSenhaAtual === false && this.validarSenhaNova === false && this.validarSenhaDiferentes === false) {

                    this.alterarSenha(this.senha).then(res => {
                        if (res.Sucesso) {
                            this.finalizarAlteracao();
                        }

                        if (!res.Sucesso) {
                            this.msgFalhaSenhaAtual = res.Mensagem;
                        }
                    })
                }

                
            },
            validarSenhas(e) {
                if (!this.senha.atual || this.senha.atual.length < 8) {
                    this.validarSenhaAtual = true;
                    e.preventDefault();
                } else { this.validarSenhaAtual = false }

                let senhaComNumero = (/\d+/g).test(this.senha.nova);
                if (this.senha.nova.length < 8 || !senhaComNumero) {
                    this.validarSenhaNova = true;
                    e.preventDefault();
                } else {
                    this.validarSenhaNova = false
                }

                if (this.senha.nova !== this.senha.confirma) {
                    this.validarSenhaDiferentes = true;
                    e.preventDefault();
                } else { this.validarSenhaDiferentes = false }

                this.salvarSenha();
            },
            finalizarAlteracao() {
                this.resetInputs();
                this.fecharPopup();
                this.abrirToastSucesso();

            },
            abrirToastSucesso() {
                this.$bvToast.toast('Senha alterada com sucesso!', {
                    title: `Sharkbot`,
                    variant: 'success',
                    autoHideDelay: 5000,
                    solid: true
                })
            }
        },
    }
</script>

<style scoped>
    .erro-input {
        color: red;
        font-size: 12px;
    }
</style>